#Login {
    background: url('../../assets/BG-Patr.webp') center center/cover no-repeat;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Login {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.LogForm {
    display: flex;
    justify-content: center;
    align-items: center;

    form {
        width: 100%;
        text-align: center;
    }
}

.log-bg {
    /* background-color: #E9F4F0; */
    background-color: #E9F4F0;
}