#footer {
    position: relative;
    height: 100%;
    padding-top: 1rem;
    background-color: #0F372A;


}

#footer::before {
    position: absolute;
    content: '';
    top: 1%;
    left: 0;
    right: 0;
    width: 100%;
    height: 3px;
    box-shadow: 1px 1px 5px 2px #0000007a;
    background-color: var(--color-primary);
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 20px 0;

}

.footer .column {
    margin-top: 20px;
    display: inline-block;
    width: 25%;
    vertical-align: top;
    padding: 0 15px;
    box-sizing: border-box;
}

.footer .column ul li {
    cursor: pointer;

    &:hover {
        color: var(--color-primary);
    }
}


.Fmap {
    padding: 1rem !important;
    margin-top: 2rem !important;
    width: 100%;
    height: 100%;

    iframe {
        width: 100%;
        min-height: 300px;
    }
}

#footer .bord {
    border: none;
    color: #fff;
}

footer a:hover {
    color: var(--color-primary);
}

footer a {
    display: block;
    color: #fff;
    text-decoration: none;
}


.under-border {
    width: 60%;
    margin-bottom: 5px;
    border-bottom: 1px solid #ffffffda;
}


#footer .bord p {
    margin: 0.2rem 0;
    font-size: 12px;
}

#footer li {
    margin-bottom: 15px;
}

.footer .C {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Fbord {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Fbord img {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 0 !important;
}

/* Optional: You can style each column individually */
.footer .column h3 {
    color: var(--color-primary);
    font-size: 16px;
    display: block;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.footer .column p {
    font-size: 14px;

}

.footer .column img {
    max-width: 90%;
    height: auto;
}

.footer-end {
    font-size: auto;
    padding: 0.8% 0;
    background-color: #032218;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
}

span a {
    padding: 0 4px;
    font-weight: bold;
    margin-right: 0.2rem;
    color: var(--color-primary);

    &:hover {
        color: white;
    }
}

/* Media queries for responsiveness */

@media screen and (max-width: 1024px) {

    .footer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


    .footer .column {
        width: 70%;
        margin-bottom: 10px;
    }

    .footer .column img {
        display: none;

    }

    .C {
        width: 70% !important;
    }


}

@media screen and (max-width: 820px) {
    footer iframe {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .footer .column {
        width: 80%;
    }


}

@media screen and (max-width: 576px) {
    .footer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 375px) {
    .footer .Fbord p {
        font-size: 8px;
        padding-bottom: 2px;
    }


}