#blog-detail {
    width: 100%;
    background-color: white;

    .Rblog-head,
    .Rblog-head a {
        color: var(--color-bg) !important;
    }

    .swiper-scrollbar-drag {
        background: var(--color-bg);

    }
}

.blog-detail {
    padding-top: 7rem;
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blog-detail-table {

    color: black;
    width: 75%;

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal !important;
    }

    a {
        color: var(--color-bg);

        &:hover {
            color: var(--color-primary);
        }
    }

    h6 {
        margin-right: 0.5rem;
        opacity: 0.6;
        font-weight: bold;
        font-size: 1.2rem;
        color: var(--color-bg);
    }

    h1 {
        padding: auto;
        font-size: 1.6rem;
        line-height: 2rem;
    }



    img {
        border-radius: 10px 10px 0 0;
        max-height: 50vh;
        object-fit: cover;
    }
}

.border-end {
    width: 40%;
    margin-top: 3rem;
    opacity: 0.5;
    border-bottom: 2px solid var(--color-bg);
}

.custom-text {
    overflow: hidden;
    width: 50%;
    margin: 0 auto;
    text-align: start;
    display: block;
}

.bullet-point {
    padding-left: 1.5em;
    /* Adjust spacing for the bullet */
    position: relative;
}



@media screen and (max-width:768px) {

    .blog-detail-table {

        h6,
        h2,
        p {
            font-size: 90%;
        }
    }

    .blog-detail-table {
        color: black;
        width: 90%;

        h6,
        h2,
        p {
            font-size: 90%;
        }

        h2 {
            padding: 2rem 0 1rem;

        }

        img {
            max-height: 20vh;
        }

        p {
            padding: 1rem 0 2rem;
        }
    }
}