.serv-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    direction: rtl;

    section {
        text-align: start;
    }

    p {
        font-size: 1.5rem;
        line-height: 1.5;
        padding-left: 2rem;
        color: var(--color-text);
    }

    h2 {
        font-weight: 600;
        font-size: 2rem;
        margin-bottom: 1.6rem;
        color: var(--color-primary);
    }

}

.serv-content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    background: var(--color-bg);
    border-radius: 50px 50px 0 0;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    width: 88%;
    margin-top: 7rem;
    padding: 4% 1.8rem 4% 0;
    overflow: hidden;

    .stampLogo {
        position: absolute;
        left: 0rem;
        bottom: -5rem;
        width: 38%;

    }
}

.serv-content-articals {

    ul,
    ol {
        line-height: 2;
        font-size: 1.4rem;
        padding-left: 2rem;
        padding-right: 1rem;
    }

    ul {

        list-style-type: square;
    }

    ol {
        list-style-type: decimal;
    }

    .serv-content-artical {
        padding-bottom: 2rem;
    }
}

.serv-content-header {

    img {
        width: 550px;
        height: 320px;
        border: 1px solid var(--color-primary);
        border-left: none;
        border-radius: 0 15px 15px 0;
        object-fit: cover;
    }

    .serv-header-top {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        gap: 1rem;
        padding: 1.5rem 1rem 1.5rem 0;
        border: 1px solid var(--color-primary);
        border-left: none;
        border-radius: 0 20px 20px 0;

    }

    .serv-header-content {
        display: flex;
        flex-direction: column;
        padding-top: 1rem;
        gap: 32%;

        h1 {
            color: var(--color-primary);

        }

        p {
            padding-left: 0;
            width: 80%;
        }
    }

}

.serv-valus-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 1.8rem;

    .serv-valus {
        position: relative;
        width: 50%;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 3.5rem;



        .serv-valus-item {
            width: 130px;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #1A4B3B;
            padding: 1rem;
            border-radius: 10px 10px 0 0;
            border: 1px solid var(--color-primary);
            border-radius: 6px 6px 0 0;


            p {
                text-align: center;
                font-size: 1rem;
                padding-left: 0;
            }

            i {
                color: var(--color-primary);
                font-size: 2.5rem;
                padding-bottom: 1rem;
            }
        }

        .valu-line {
            z-index: 1;
            position: absolute;
            background-color: var(--color-primary);
            height: 1px;
            width: 100%;
        }
    }
}

.serv-btns {
    display: flex;
    align-items: center;
    gap: 2rem;
    font-weight: bold;

    :first-child {
        border: 2px solid transparent;
        background: var(--color-primary);

    }

    .serv-btn {
        padding: 0.3rem 2.5rem;
        border-radius: 10px;
    }

    :first-child,
    :last-child {
        transition: all ease 0.2s;

        &:hover {
            transition: all ease 0.2s;
            background: var(--color-bg);
            color: var(--color-primary);
            border: 2px solid var(--color-primary);
        }
    }

    :last-child {

        border: 2px solid var(--color-primary);
    }

}

@media only screen and (max-width: 1265px) {
    .serv-header-content {
        gap: 20% !important;
    }
}


@media only screen and (max-width: 1100px) {
    .serv-header-content {
        gap: 18% !important;

    }

    .serv-content-container {

        p {
            font-size: 1.2rem;
            line-height: 1.5;
            padding-left: 2rem;
            color: var(--color-text);
        }

        .serv-content-artical {
            padding-bottom: 2rem;
        }

        .serv-header-content {
            p {
                padding-left: 0;
                width: 95%;
            }
        }


    }


    .serv-valus-container {
        .serv-valus {
            width: 70%;

        }
    }



}

@media only screen and (max-width: 865px) {
    .serv-header-content {
        gap: 20% !important;
    }

    .serv-content {
        margin-top: 2.5rem;

    }

    .serv-content-header {

        img {
            max-width: 550px;
            border: 1px solid var(--color-primary);
            border-left: none;
            border-radius: 0 15px 15px 0;
            object-fit: cover;
        }

        .serv-header-top {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: end;
            gap: 1rem;
            padding: 1.5rem 0rem 1.5rem 0;
            border: 1px solid var(--color-primary);
            border-left: none;
            border-radius: 0 20px 20px 0;

        }

        .serv-header-content {

            display: flex;
            flex-direction: column;
            padding-top: 1rem;
            padding-right: 1.5rem;
            gap: 32%;

            h1 {
                color: var(--color-primary);

            }

            p {
                padding-left: 0;
                width: 80%;
                padding-top: 1rem;
            }
        }

    }


}

@media only screen and (max-width: 674px) {
    .serv-valus-container {
        .serv-valus {
            width: 98%;
        }
    }
}


@media only screen and (max-width: 590px) {
    .serv-valus-container {
        .serv-valus {
            width: 98%;
        }
    }

    .serv-content-header {

        img {
            max-width: 90%;
            border: 1px solid var(--color-primary);
            border-left: none;
            border-radius: 0 15px 15px 0;
            object-fit: cover;
        }
    }
}

@media only screen and (max-width: 519px) {
    .serv-valus-container {
        .serv-valus {
            .serv-valus-item {
                width: 90px;

                p {
                    text-align: center;
                    font-size: 0.6rem;
                    padding-left: 0;
                }

                i {

                    font-size: 2rem;

                }
            }

        }
    }
}

@media only screen and (max-width: 400px) {
    .serv-content-container {


        p {
            font-size: 1.1rem;

        }

        h2 {
            font-weight: bolder;
            font-size: 1.2rem;

        }

        .serv-content-articals {

            ul,
            ol {
                line-height: 2;
                font-size: 1.1rem;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            ul {

                list-style-type: square;
            }

            ol {
                list-style-type: decimal;
            }
        }

    }
}