.sticky-i {
    position: fixed;
    bottom: -6%;
    right: 2%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 999999;
}

.sticky-i a {
    display: block;
    text-align: center;
    padding: 14px;
    margin-bottom: 15px;
    transition: all 0.3s ease;
    color: rgb(255, 255, 255);
    font-size: 24px;
    border-radius: 10px 10px 0 0;
}

.S-btn {
    background: var(--color-primary-op);
}

.S-btn:hover {
    background: var(--color-primary);
}


.sticky-i .hidden {
    cursor: default;
    opacity: 0 !important;
}

.slider-img {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.slider-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    right: 15%;
    bottom: 33% !important;
    left: 15%;

    img {
        display: none;
        width: 150px;
    }
}

.slider-content h5 {
    text-shadow: 2px 2px 10px var(--color-primary);
    font-size: 40px;
    line-height: 7rem;
}

.carousel-control-prev,
.carousel-control-next {
    display: none !important;
}

#showcase .Cbg img {
    width: 100%;
    height: 100vh;
}

.whatsLogo {
    width: 30px;
}

@media only screen and (max-width: 1600px) {
    .slider-content {
        position: absolute;
        right: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 32% !important;
        left: 15%;
    }

    .slider-content h5 {
        font-size: 36px;
        line-height: 6.5rem;
    }
}

@media only screen and (max-width: 1366px) {
    .slider-content {
        position: absolute;
        right: 15%;
        bottom: 30% !important;
        left: 15%;
    }

    .slider-content h5 {
        font-size: 32px;
        line-height: 6rem;
    }
}

@media only screen and (max-width: 1200px) {
    #showcase .Cbg img {
        height: 80vh;
    }

    .slider-content {
        h5 {
            font-size: 25px;
        }
    }
}

@media screen and (max-width: 1024px) {
    #showcase .Cbg img {
        height: 70vh;
    }

    .slider-content {
        bottom: 35% !important;

        h5 {
            line-height: 4rem;
            font-size: 25px;
        }
    }

}

@media screen and (max-height: 412px) and (max-width: 950px) {
    #showcase .Cbg img {
        height: 90vh;
    }

    .slider-content {
        bottom: 27% !important;

        h5 {

            font-size: 20px;
        }
    }
}

@media (max-width: 802px) {
    #showcase .Cbg img {
        height: 98vh;
        object-fit: cover;
    }


    .slider-content {

        line-height: normal !important;

        h5 {
            font-size: 22px;
            line-height: 4rem;
        }

        img {
            display: block;
            width: 150px;
        }
    }


}

@media (min-width: 768px) {
    #showcase .Cbg img {
        height: 100vh;
        object-fit: cover;
    }
}


@media screen and (max-width: 660px) {

    .slider-content {

        h5 {
            font-size: 18px;
            line-height: 4rem;
        }

        img {
            display: block;
            width: 120px;
        }
    }

    .carousel-indicators [data-bs-target] {
        width: 20px;
        height: 2px;
    }

}

@media screen and (max-width: 550px) {
    .slider-content h5 {
        font-size: 14px;
    }

    .sticky-i a {
        font-size: 18px;
        padding: 12px;
    }
}

@media screen and (max-width: 420px) {
    .slider-content h5 {
        font-size: 13px;
        line-height: 3rem;
    }

}


@media screen and (max-width: 391px) {

    .slider-content h5 {
        line-height: 2.2rem;
    }

    .sticky-i a {
        font-size: 16px;
        padding: 10px;
    }
}

@media screen and (max-width: 300px) {

    .slider-content {
        font-size: 14px;
    }

}