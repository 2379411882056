@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: 0;

}

:root {
    --color-bg: #175541;
    --color-bg-op: #175541ea;
    --color-primary: #C79149;
    --color-primary-op: #C79149ad;
    --color-white: #ffffff;
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
    --transition: all 400ms ease;
    --border: 1px solid var(--color-primary-vartiant);
}


body {
    font-family: "Cairo", sans-serif;
    background: var(--color-bg) !important;
    color: var(--color-white);
    line-height: 1.7;
}

#main {
    overflow: hidden;
}

.main-bg-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 100%;
}

.main-bg {
    background-image:
        url('/src/assets/Side-left.webp'),
        url('/src/assets/Side-right.webp');



    background-position:
        left center,
        right center;
    background-repeat: repeat-y, repeat-y;
    object-fit: cover;
    height: 100%;
}

a:hover {
    color: var(--color-white);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: var(--border);
    transition: var(--transition);
}

.btn:hover {
    background-color: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}


.container {
    width: var(--container-width-lg);
    margin: 0, auto;
    display: inline-block;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section {
    text-align: center;
}

section>h2,
section>h5 {
    text-align: center;
    color: var(--color-light);
}

section>h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);
}

.PagiNation {
    position: absolute;
    bottom: 1rem;
}

.PagiNation .page-link {
    color: var(--color-primary) !important;
}

.PagiNation .active>.page-link,
.page-link.active {
    background-color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
    color: var(--sec-text-color) !important;
    border-radius: 4px;


}

.PagiNation .disabled>.page-link,
.page-link.disabled {
    background-color: rgb(219, 219, 223) !important;
    border-color: rgb(219, 219, 223) !important;
}

@media screen and (max-width:500px) {
    .main-bg {
        background-size: 12%;
    }
}