#Eblogs {
    position: relative;
    min-height: 100vh;
    width: 100%;
    background: url('../../assets/BG-Patr.webp') center center/cover no-repeat;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Eblogs {
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Eblog-head {
    color: black;
    margin-top: 1%;
    padding: 1rem 2rem;
    border-radius: 10px;
    background-color: #E9F4F0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
        direction: rtl;
        width: 25%;
        height: 2rem;
        margin: 0.5rem;
        background-color: white;
        border: 1px solid var(--color-primary);
        border-radius: 5px;

        &::placeholder {
            padding-right: 0.5rem;
        }
    }
}

.Eblog-head1 {
    display: flex;
    align-items: center;
    gap: 1.5rem;

    a,
    button {
        font-size: small;
        background-color: var(--color-primary);
        border-radius: 5px;
        padding: 0.3rem 0.6rem;
        transition: all 0.2s ease;

        &:hover {
            background-color: var(--color-bg);
            color: white;
        }
    }
}

.Eblog-head2 {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
        width: 50%;
    }
}

.admin-Bcard {
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
    height: 100%;
    border-radius: 10px;
    margin: 0.5rem;

    button {
        margin: 0 0.5rem;
        color: black;
    }
}

.admin-blog-container {
    padding-top: 0.5rem;
    margin-top: 1rem;
    min-width: 80vw;
    min-height: 100vh;
    background: #E9F4F0;
    border-radius: 10px;
}

.admin-card-container {
    width: 80%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, 280px);

}

.Adhead {
    position: absolute;
    top: 40%;
    left: 1rem;

    button {
        font-size: small;
        border-radius: 5px;
        padding: 0.2rem 0.6rem;
        background-color: var(--color-primary);
        transition: all 0.2s ease;

        &:hover {
            color: white;
            background-color: var(--color-bg);
        }
    }
}



@media screen and (max-width:977px) {
    .Eblog-head {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .Eblog-head2 {
        margin-top: 0.5rem;
    }
}

@media screen and (max-width:565px) {

    .Eblog-head2 {
        width: 100%;
        flex-direction: column;
    }

    .admin-card-container {
        justify-content: center;
    }
}