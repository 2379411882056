#about {
    height: 100% !important;
    position: relative;

}

#about .sec-border {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.about-container {
    z-index: 3;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: var(--color-bg);
    width: 88.5%;
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px 20px 0 0;
    box-shadow: 2px 2px 10px 2px #0000007a;
    color: #fff;

    h1 {
        position: relative;
        width: 100%;
        color: var(--color-primary);
        margin: 2rem 0;
        display: inline-block;
        font-size: 30px;
        font-weight: bold;
        padding: 0 10px;

        span {

            background-color: var(--color-bg);
            padding: 0.5rem 5%;
        }
    }

    h1::after {
        position: absolute;
        content: '';
        z-index: -1;
        background-color: var(--color-primary);
        top: 55%;
        width: 45%;
        height: 2px;
        right: 3rem;
    }

    h1::before {
        position: absolute;
        left: 3rem;
        content: '';
        z-index: -1;
        background-color: var(--color-primary);
        width: 45%;
        top: 55%;
        height: 2px;
    }


}


.text-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 2rem;
    cursor: pointer;
    transition: all linear 0.3s;


    p {
        font-size: 24px;
        width: 80%;
        line-height: 6rem;

    }
}


.Mis-vig {
    display: flex;
    width: 82%;
    margin-top: 2rem;
    justify-content: space-around;
    align-items: center;
}

.Mis-vig-content {
    position: relative;
    height: 500px;
    width: 450px;
    border: 2px solid var(--color-primary);
    border-radius: 7px;
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;

    p {
        direction: rtl;
        margin-top: 1.5rem;
        font-size: 22px;
        text-align: start;
        padding: 1rem 1.5rem;
        line-height: 4rem;
    }

    h4 {
        position: absolute;
        font-size: 25px;
        top: -20px;
        right: 15%;
        padding: 0 0.5rem;
        background-color: var(--color-bg);
        color: var(--color-primary);
    }
}

@media screen and (max-width: 1600px) {
    .text-container {

        p {

            line-height: 4rem;

        }
    }
}

@media screen and (max-width: 1366px) {
    .text-container {

        p {
            font-size: 22px;
            width: 80%;
            line-height: 3.5rem;

        }
    }

    .Mis-vig-content {
        position: relative;
        height: 400px;
        width: 320px;
        border: 2px solid var(--color-primary);
        border-radius: 7px;
        margin-bottom: 3rem;
        display: flex;
        justify-content: center;

        p {
            direction: rtl;
            margin-top: 1.5rem;
            font-size: 18px;
            text-align: start;
            padding: 1rem 1.5rem;
            line-height: 3rem;
        }

        h4 {
            position: absolute;
            font-size: 25px;
            top: -20px;
            right: 15%;
            padding: 0 0.5rem;
            background-color: var(--color-bg);
            color: var(--color-primary);
        }
    }
}

@media screen and (max-width: 768px) {
    .text-container {

        p {
            font-size: 20px;
            width: 80%;
            line-height: 3rem;

        }
    }

    .Mis-vig {
        display: flex;
        width: 82%;
        margin-top: 2rem;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
    }

    .Mis-vig-content {
        position: relative;

        border: 2px solid var(--color-primary);
        border-radius: 7px;
        margin-bottom: 3rem;
        display: flex;
        justify-content: center;

        p {
            direction: rtl;
            margin-top: 1.5rem;
            font-size: 20px;
            text-align: start;
            padding: 1rem 1.5rem;
            line-height: 2.5rem;
        }

        h4 {
            position: absolute;
            font-size: 25px;
            top: -20px;
            right: 15%;
            padding: 0 0.5rem;
            background-color: var(--color-bg);
            color: var(--color-primary);
        }
    }
}

@media screen and (max-width: 550px) {
    .text-container {

        p {
            font-size: 16px;
            width: 100%;
            line-height: 2.5rem;

        }
    }

    .Mis-vig {
        display: flex;
        width: 82%;
        margin-top: 2rem;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
    }

    .Mis-vig-content {
        position: relative;

        border: 2px solid var(--color-primary);
        border-radius: 7px;
        margin-bottom: 3rem;
        display: flex;
        justify-content: center;

        p {
            direction: rtl;
            margin-top: 1.5rem;
            font-size: 16px;
            text-align: start;
            padding: 1rem 1.5rem;
            line-height: 2.5rem;
        }


    }
}