#add-blog {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background: url('../../assets/BG-Patr.webp') center center/cover no-repeat;
    margin: 0;
    padding: 0;
}

.add-blog {
    width: 80%;
}

.error-message {
    color: red;
}

.color-picker {
    position: absolute;
    z-index: 1000;
    top: 60px;
    /* Adjust as needed */
    right: 20px;
    /* Adjust as needed */
}

.add-board {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    color: black;
    margin: 2rem 0;
    padding: 0.5rem 0;
    background: #E9F4F0;

    p {
        padding-top: 2rem;
        margin: 0;
        width: 78%;
        text-align: start;
    }
}

.add-board-bot {
    padding: 2rem 0;
    width: 75%;
    display: flex;
    justify-content: space-between;
    align-items: center;


    button {
        font-size: small;
        font-size: large;
        background-color: var(--color-primary);
        border-radius: 5px;
        padding: 0.3rem 0.6rem;
        transition: all 0.2s ease;

        &:hover {
            background-color: var(--color-bg);
            color: white;
        }
    }
}

#add-blog input,
#add-blog textarea {
    width: 80%;
    border: 1px solid var(--color-primary);
    border-radius: 5px;
    background-color: white;

    &::placeholder {
        padding: 0 0.5rem;
    }
}

#add-blog input {
    margin-top: 0.5rem;

}

@media screen and (max-width: 768px) {
    #add-blog .Eblog-head {
        flex-direction: column-reverse;

        h2 {
            margin-bottom: 0.8rem;
        }

    }
}

@media screen and (max-width: 650px) {
    .add-board-bot {
        flex-direction: column-reverse;

        button {
            margin-top: 2rem;
        }
    }
}