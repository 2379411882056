#blogcard {
    width: 96%;
    height: 260px;
    margin: 0.5rem;
    background-color: #fff;
    border-radius: 20px 20px 0 0;
    box-shadow: 2px 2px 5px 1px #0000007a;
    transition: all 0.2s linear;
    overflow: hidden;

    a {
        text-decoration: none;
        color: #000;
    }
}

#blogcard:hover {
    box-shadow: 2px 2px 10px 2px #0000007a;
    transition: all 0.2s linear;
    transform: translateY(-10px);
}

.blogcard__image img {
    object-fit: cover;
    width: 100%;
    max-height: 100px;
}

.BlogCard__content {
    height: 160px;
    overflow: hidden;
    margin: 0.5rem 1rem;

    .strong {
        margin: 0 0 0.5rem;
    }

    h6 {
        overflow: hidden;
        max-height: 2.2rem;
        font-size: 0.9rem;
    }

    p {
        overflow: hidden;
        max-height: 4rem;
        font-size: 0.8rem;
        color: #000;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: #000;
            font-size: 0.8rem;
        }
    }


}

@media screen and (max-width: 1366px) {
    #blogcard {
        margin: 0.5rem;
        background-color: #fff;
        border-radius: 20px 20px 0 0;
        box-shadow: 2px 2px 5px 1px #0000007a;
        transition: all 0.2s linear;
        overflow: hidden;
    }

    .blogcard__image img {
        object-fit: cover;
        width: 100%;
        max-height: 100px;
    }

    .BlogCard__content {
        height: 160px;
        overflow: hidden;
        margin: 0.5rem 1rem;

        .strong {
            margin: 0 0 0.5rem;
        }

        p {
            font-size: 0.8rem;
        }


    }
}