#Rblog {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 4rem 0;
}

.Rblog {
    max-width: 88vw;
    min-width: 88vw;
}

.Rblog-head {
    color: #fff;
    margin: 0 1rem 0.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
        color: #fff;
        text-decoration: none;
        transition: all 0.1s linear;

    }

    a:hover {
        transition: all 0.1s linear;
        transform: translateX(-5px);
    }
}

.Rblog-container {
    display: flex;
    justify-content: center !important;
    border-radius: 20px 20px 0 0;
    box-shadow: 2px 2px 10px 2px #0000007a;
    background-color: #E9F4F0;
    padding: 1rem;
}

/* Rblog.css */


/* Pagination styles */
/* .swiper-pagination {
    top: 0;
    
}

.swiper-pagination-bullet {
    background-color: #007bff;
    
    width: 12px;
    
    height: 12px;
    
    opacity: 0.7;
    
} 

.swiper-pagination-bullet-active {
    background-color: #0056b3;
    
    opacity: 1;
    
}
*/

/* Scrollbar styles */
.swiper-scrollbar {
    background: rgba(16, 29, 16, 0.185);
    /* Scrollbar track color */
    height: 8px;
    /* Scrollbar height */
    border-radius: 4px;
    /* Scrollbar border radius */
}

.swiper-scrollbar-drag {
    background: var(--color-primary);
    /* Scrollbar drag color */
    border-radius: 4px;
    /* Drag border radius */
}


@media screen and (max-width:1024px) {
    .Rblog-container {
        display: flex;
        gap: 1.5rem;
        flex-wrap: wrap;
    }
}