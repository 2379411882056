#serv .about-container {
    h1 span {
        background-color: transparent;
    }

    h1::after,
    h1::before {
        width: 35%;
    }

}

#serv .sec-border {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

#serv .about-container {
    height: 100%;
    padding-bottom: 5%;
    background: url('/src/assets/services.webp') center center/cover var(--color-bg);

}

.serv-container {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
}

.serv-card {
    cursor: pointer;
    overflow: hidden;
    border-radius: 20px 20px 0 0;
    width: 450px;
    height: 350px;
    margin: 1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-primary);
    box-shadow: 2px 2px 10px #2222227a;
    transition: all 0.15s ease;
    transform: translateY(0px);

    img {
        border-radius: 20px 20px 0 0;
    }

    .hover-bg {

        width: 100%;
        height: 100%;
        transition: all 0.35s linear;
        background-color: var(--color-bg-op);
        position: absolute;
        transform: translateY(100%);

        a {
            width: 100%;
            height: 100%;
        }

        .hover-div {
            width: 100%;
            height: 100%;
            flex-direction: column !important;
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
        }


    }

    &:hover {
        transform: translateY(-15px) !important;
        transition: all 0.15s ease;
        box-shadow: 2px 2px 10px #2222227a;

        .hover-bg {
            transition: all 0.35s linear;
            transform: translateY(0%) !important;
        }

    }
}

.more-btn {
    padding-top: 0.5rem;
    color: var(--color-bg);
}




.card-conrent {
    width: 90%;
    margin-top: 2%;
    display: flex;
    direction: rtl;
    text-align: start;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h5 {
        font-weight: 600;
        width: 100%;
        margin-bottom: 1rem;
        font-size: 1.7rem;
        text-align: start;
        color: var(--color-bg);
    }

    p {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 1682px) {

    .serv-card {
        border-radius: 20px 20px 0 0;
        width: 400px;
        height: 300px;
        margin: 1rem;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--color-primary);
        box-shadow: 2px 2px 10px #2222227a;

        img {
            border-radius: 20px 20px 0 0;
        }
    }

    .card-conrent {
        h5 {
            font-size: 1.2rem;
        }

        p {
            font-size: 1rem;
        }
    }

}

@media screen and (max-width: 1485px) {
    .serv-card {
        width: 350px;
        height: 300px;

    }
}

@media screen and (max-width: 1366px) {
    .serv-card {
        width: 300px;
        height: 250px;
    }

    .card-conrent {


        h5 {

            font-size: 1.1rem;


        }

        p {
            font-size: 0.8rem;
        }
    }
}

@media screen and (max-width: 1131px) {
    .serv-container {
        grid-template-columns: repeat(2, 1fr);
    }

}

@media screen and (max-width: 700px) {
    .about-container {
        h1 {
            font-size: 1.5rem;
        }
    }

    #serv .about-container {
        h1::after {
            position: absolute;
            content: '';
            z-index: -1;
            background-color: var(--color-primary);
            top: 55%;
            width: 22%;
            height: 2px;
            right: 3rem;
        }

        h1::before {
            position: absolute;
            left: 3rem;
            content: '';
            z-index: -1;
            background-color: var(--color-primary);
            width: 22%;
            top: 55%;
            height: 2px;
        }

    }

}

@media screen and (max-width: 655px) {
    .serv-container {
        grid-template-columns: repeat(1, 1fr);
    }

}