#Blogpage {
    position: relative;

    .head-pg {
        width: 90%;

        h1 {
            position: relative;
            width: 100%;
            color: var(--color-primary);
            margin: 2rem 0;
            display: inline-block;
            font-size: 30px;
            font-weight: bold;
            padding: 0 10px;
            text-align: center;
        }

        span {
            background-color: white;
            padding: 0.5rem 5%;
        }
    }

    .head-pg h1::after {
        position: absolute;
        content: '';
        z-index: -1;
        background-color: var(--color-primary);
        top: 55%;
        width: 45%;
        height: 2px;
        right: 6rem;
    }

    .head-pg h1::before {
        position: absolute;
        left: 6rem;
        content: '';
        z-index: -1;
        background-color: var(--color-primary);
        width: 45%;
        top: 55%;
        height: 2px;
    }
}

.Blog-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    padding: 6rem 0 4rem;
}

.Blog-card-container {
    vertical-align: middle;
    width: 90%;
    gap: 0.8rem;
    margin: 1rem auto;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, 336px);
}